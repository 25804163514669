import { Spinner } from "../../../components/Spinner";
import {
  useDatosTramiteMutation,
  useGetPolizaQuery,
  useNuevoTramiteMutation,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import { toastr } from "react-redux-toastr";

export const NuevoTramitePoliza = () => {
  const { id } = useParams();
  const { data: poliza, isLoading: cargandoPoliza } = useGetPolizaQuery(id);
  const [cobertura_seleccionada, set_cobertura_seleccionada] = useState(null);
  const {
    formulario: dos,
    handleInputChange: hcDos,
    handleChangeAll: hcaDos,
  } = useForm({
    fi_cobertura_id: 0,
    fi_poliza_id: 0,
    fi_numero_asegurado: 0,
    fc_clave_asegurado: "",
    fecha_siniestro: "",
    siniestro: "",
    montoReclamar: "",
    nombreContacto: "",
    telefonoContacto: "",
    correoContacto: "",
    descripcion: "",
    fc_poliza_aseguradora: "",
  });
  const [nuevoTramite, { isLoading: isLoadingTramite }] =
    useNuevoTramiteMutation();
  const [datosTramite, { isLoading: isLoadingDatos }] =
    useDatosTramiteMutation();
  const [siniestroGenerado, setSiniestroGenerado] = useState(false);
  const [seleccionSiniestro, setSeleccionSiniestro] = useState(null);
  const iniciarTramite = (p, a, c) => {
    set_cobertura_seleccionada(c);
    let formDos = {
      fi_cobertura_id: c.tcc_id,
      fi_numero_asegurado: a.tca_fi_numero_asegurado,
      fc_clave_asegurado: a.tca_fc_clave_asegurado,
      fc_poliza: a.ttap_fc_numero_poliza,
      fecha_siniestro: "",
      fc_poliza_aseguradora: a.fc_poliza_aseguradora,
      fi_poliza_id: p.ttp_id,
    };
    hcaDos(formDos);
  };
  const iniciarTramiteUno = async () => {
    // const tramite = await nuevoTramite({
    //   ...uno,
    //   fecha_siniestro,
    // })
    //   .unwrap()
    //   .catch((err: any) => {
    //     if (err.status === 400 || err.status === 404) {
    //       if (err.data.message) {
    //         if (Array.isArray(err.data.message)) {
    //           err.data.message.map((m: any) => {
    //             toastr.error(m);
    //           });
    //         } else {
    //           toastr.error(err.data.message);
    //         }
    //       }
    //     }
    //   });
    // if (tramite) {
    //   if (tramite.step === 2) {
    //     let fecha = tramite.fecha_siniestro.split("-");
    //     fecha_siniestro = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
    //   }
    //   let formDos = {
    //     fi_cobertura_id: uno.fi_cobertura_id,
    //     fi_numero_asegurado: uno.fi_numero_asegurado,
    //     fc_clave_asegurado: uno.fc_clave_asegurado,
    //     fecha_siniestro,
    //     siniestro: tramite.siniestro,
    //     montoReclamar: "",
    //     nombreContacto: "",
    //     telefonoContacto: "",
    //     correoContacto: "",
    //     descripcion: "",
    //   };
    //   hcaDos({ ...formDos });
    //   setSeleccionSiniestro(tramite.siniestro);
    //   console.log(formDos);
    // }
  };
  const generarTramite = async () => {
    if (!dos.fecha_siniestro) {
      toastr.error("Debe seleccionar la fecha del siniestro");
      return;
    }
    let fecha_buena = dos.fecha_siniestro.split("-");
    let fecha_siniestro =
      fecha_buena[2] + "-" + fecha_buena[1] + "-" + fecha_buena[0];
    const tramite = await datosTramite({
      ...dos,
      fecha_siniestro,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });

    if (tramite) {
      setSiniestroGenerado(true);
      setSeleccionSiniestro(tramite.siniestro);
      toastr.info(tramite.siniestro);
    }
  };

  return (
    <>
      {!poliza && cargandoPoliza && <Spinner />}
      {poliza && poliza.poliza && poliza.poliza.length > 0 && (
        <div className="mt-10 grid place-items-stretch gap-4 sm:grid-cols-1 md:grid-cols-2">
          {poliza.poliza.map((p: any) => {
            return (
              <div key={p.ttp_id} className="card rounded">
                <div className="px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {p.ttp_fi_numero_producto} - {p.tcp_fc_descripcion_producto}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    {p.tcp_fc_descripcion_aseguradora}
                  </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                      <a target="_blank" href={p.ttp_fc_url_poliza}>
                        Descargar Póliza
                      </a>
                    </p>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Póliza Interna:
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {p.ttp_fc_numero_poliza}
                        <span className="ml-2 inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {p.ttp_fc_estatus_poliza}
                        </span>
                      </dd>
                    </div>
                    <div className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Asegurados:
                      </dt>
                    </div>
                    {p.asegurados.map((a: any) => {
                      return (
                        <Fragment key={a.ttap_id}>
                          <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="mt-2 text-sm font-medium leading-6 text-gray-900">
                              Nombre:
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div className="w-full">
                                <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                  {a.fc_descripcion_tipo_asegurado}
                                </span>
                              </div>
                              {a.tca_fc_nombre_asegurado}
                            </dd>
                          </div>
                          <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              RFC:
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div className="w-full">
                                <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                  {a.fc_descripcion_tipo_asegurado}
                                </span>
                              </div>
                              {a.tca_fc_rfc}
                            </dd>
                          </div>
                          <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Correo:
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div className="w-full">
                                <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                  {a.fc_descripcion_tipo_asegurado}
                                </span>
                              </div>
                              {a.tca_fc_email}
                            </dd>
                          </div>
                          {a.ttap_fi_tipo_asegurado !== 9 &&
                            a.coberturas.length > 0 && (
                              <>
                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Coberturas:
                                  </dt>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  {a.coberturas.map((c: any) => {
                                    return (
                                      <dd
                                        key={c.tcc_id}
                                        className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                                      >
                                        <div className="w-full">
                                          <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            {c.tcc_fc_ramo}
                                          </span>
                                          <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            {c.ttcp_fn_valor_is_cobertura}
                                          </span>
                                        </div>
                                        {c.ttcp_fi_numero_cobertura} -{" "}
                                        {c.tcc_fc_descripcion_cobertura}
                                        {c.tcc_fi_protec === 1 && (
                                          <div className="w-full text-right">
                                            <button
                                              onClick={() =>
                                                iniciarTramite(p, a, c)
                                              }
                                              className="m-1 rounded border border-gray-500/10 bg-primary-ultra-light px-4 py-2 text-sm text-black"
                                            >
                                              Iniciar Tramite
                                            </button>
                                          </div>
                                        )}
                                      </dd>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                        </Fragment>
                      );
                    })}
                  </dl>
                </div>
              </div>
            );
          })}
          {cobertura_seleccionada && (
            <div className="card rounded">
              <div className="px-4 sm:px-0">
                <h6 className="text-base font-semibold leading-7 text-gray-600">
                  Cobertura a reclama
                </h6>
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  {cobertura_seleccionada.tcc_fc_descripcion_cobertura}
                </h3>
                <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                  <div className="item">
                    <div className="flex flex-col px-2 py-2">
                      <Label htmlFor="" className="my-2">
                        Fecha de Siniestro (dd-mm-yyyy)
                      </Label>
                      <Input
                        type="date"
                        name="fecha_siniestro"
                        onChange={hcDos}
                        value={dos.fecha_siniestro}
                      />
                    </div>

                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        <Label htmlFor="" className="my-2">
                          Descripción del evento:
                        </Label>
                        <Input
                          type="text"
                          name="descripcion"
                          onChange={hcDos}
                          value={dos.descripcion}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        <Label htmlFor="" className="my-2">
                          Monto a Reclamar
                        </Label>
                        <Input
                          type="text"
                          name="montoReclamar"
                          onChange={hcDos}
                          value={dos.montoReclamar}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        <Label htmlFor="" className="my-2">
                          Nombre Contacto
                        </Label>
                        <Input
                          type="text"
                          name="nombreContacto"
                          onChange={hcDos}
                          value={dos.nombreContacto}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        <Label htmlFor="" className="my-2">
                          Correo Contacto
                        </Label>
                        <Input
                          type="text"
                          name="correoContacto"
                          onChange={hcDos}
                          value={dos.correoContacto}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        <Label htmlFor="" className="my-2">
                          Telefono Contacto
                        </Label>
                        <Input
                          type="text"
                          name="telefonoContacto"
                          onChange={hcDos}
                          value={dos.telefonoContacto}
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="flex flex-col px-2 py-2">
                        {!siniestroGenerado && !isLoadingDatos && (
                          <button
                            onClick={() => generarTramite()}
                            className="m-1 rounded border border-gray-500/10 bg-primary-ultra-light px-4 py-2 text-sm text-black"
                          >
                            Generar Tramite
                          </button>
                        )}
                        {!siniestroGenerado && isLoadingDatos && <Spinner />}
                      </div>
                    </div>
                  </div>
                </div>
                {seleccionSiniestro && (
                  <>
                    <div className="item">
                      <div className="flex flex-col px-6 py-2">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                          {!siniestroGenerado && <p>Completar Siniestro:</p>}
                          {siniestroGenerado && (
                            <p>Siniestro en Carga de documentación:</p>
                          )}
                          {seleccionSiniestro}
                        </h3>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
