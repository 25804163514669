import {
  useCargarArchivoBucketMutation,
  useGetPendientesQuery,
} from "../../../services/docs";
import { Spinner } from "../../../components/Spinner";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import { useForm } from "../../../hooks/useForm";

export const CargaBucketScreen = () => {
  const { data, isLoading } = useGetPendientesQuery();
  const [cargaAction, { isLoading: cargando }] =
    useCargarArchivoBucketMutation();
  const { formulario, handleInputChange, handleChange } = useForm({
    fc_ruta: "",
    archivo: "",
    fc_mime: "",
  });

  const onChange = (e: any, campo: string) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, campo);
  };
  const getBase64 = (file: any, campo: string) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result, campo);
    };
  };
  const onLoad = (fileString: any, campo: string) => {
    console.log(fileString);
    handleChange(campo, fileString);
  };
  const guardarArchivo = async () => {
    console.log(formulario);
    const resp = await cargaAction({
      ...formulario,
    })
      .unwrap()
      .catch((err) => {
        toastr.error("No se puedo cargar");
        console.log(err);
      });
    if (resp) {
      toastr.info("Archivos cargados");
    }
  };
  return (
    <div className="card">
      <div className="space-y-6">
        <div className="mb-3 mt-5 w-full">
          <div className="flex items-center">
            <div className="w-3/4">
              <h6>Cargar Archivos</h6>
            </div>
            <div className="mb-1 w-1/4"></div>
          </div>
          <hr />
        </div>
        <div className="my-2">
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="fc_valor">
              Ruta:
            </Label>
            <Input type="text" name="fc_ruta" onChange={handleInputChange} />
          </div>
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="fc_mime">
              Mime:
            </Label>
            <Input type="text" name="fc_mime" onChange={handleInputChange} />
          </div>
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="archivo">
              Archivo:
            </Label>
            <input
              onChange={(e) => {
                onChange(e, "archivo");
              }}
              type="file"
            />
          </div>
          {!cargando && (
            <div className="flex flex-col px-6 py-2">
              <Button onClick={guardarArchivo}>Guardar</Button>
            </div>
          )}
          {cargando && <Spinner />}
        </div>
      </div>
    </div>
  );
};
